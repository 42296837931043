
.App {
  background: white;
  font-family: "Montserrat", sans-serif;
  height: 100%;
}

.container {
  display: flex;
  min-width: 100%;
}

.left_container {
  padding: 40px 80px;
  min-height: 100%;
  width: 65%;
}

@media screen and (min-width: 1024px) {
  .evaluators {
    height: 620px;
  }
}
.right_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  min-height: 100vh;
}

.grey {
  background: rgb(229 231 235);
  transition: background-color 0.5s ease-out;
}
.green {
  background: rgba(49, 138, 28, 0.9);
  transition: background-color 0.5s ease-out;
}
.orange {
  background: rgba(229, 129, 32, 0.9);
  transition: background-color 0.5s ease-out;
}
.red {
  background: rgba(145, 31, 31, 0.9);
  transition: background-color 0.5s ease-out;
}

.line {
  background: rgba(142, 142, 142, 0.18);
  height: 5px;
  border-radius: 100px;
  margin-top: 15px;
  margin-bottom: 20px;
}

.alert {
  margin-bottom: 20px;
  width: 400px;
  font-family: "Montserrat", sans-serif;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  text-align: center;
  color: #e5e5e5;
}

.alertBtn {
  background-color: transparent;
  border: 0;
  justify-self: flex-end;
  cursor: pointer;
  z-index: 1000;
}

.info {
  background-color: rgba(82, 82, 82, 0.78);
}
.success {
  background-color: rgba(39, 155, 52, 0.78);
}
.error {
  background-color: rgba(154, 31, 31, 0.78);
}
